// i18next-extract-mark-ns-start terms_free_tours
import * as React from "react"
import { graphql } from "gatsby"
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageSection from "../../components/page-section"

const TermsOfServicePageFreeTours = () => {

  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t("Regulamin rejsów bezpłatnych")} />
      <PageSection>
        <h1 className="pb-3"><Trans>Regulamin rejsów bezpłatnych</Trans></h1>
      </PageSection>
      <PageSection className="bg-light text-black">
        <h3><Trans>Regulamin bezpłatnych rejsów Stołecznej Żeglugi Pasażerskiej</Trans>.</h3>
        <h5 className="my-5 fw-bold">§1. <Trans>Organizator Cyklu Bezpłatnych Rejsów</Trans></h5>
        <p>1. <Trans>Organizatorem, koordynatorem Cyklu Bezpłatnych Rejsów Stołecznej Żeglugi Pasażerskiej jest Rosa Venti Sp. z o. o. ul. Nowy Świat 33 / 13, 00-029 Warszawa NIP: 525-281-76-48 na zlecenie Zarządu Zieleni w Warszawie</Trans>.</p>
        <p>2. <Trans>Projekt finansowany jest z budżetu obywatelskiego na rok 2022 Miasta St. Warszawy</Trans>.</p>
        <p>3. <Trans>Organizator współpracuje przy realizacji projektu z armatorami wynajmującymi jednostki, posiadające odpowiednie aktualne dokumenty, oraz wykorzystuje własne jednostki</Trans>.</p>
        <h5 className="my-5 fw-bold">§2. <Trans>Rejs</Trans></h5>
        <p>1. <Trans>Za rejs rozumiana jest 1 godzinna wycieczka po Wiśle różnymi jednostkami przystosowanymi do żeglugi pasażerskiej po Wiśle</Trans>.</p>
        <p>2. <Trans>Rejs rozpoczyna się w 3 lokalizacjach: Przystań Młociny, Dworzec Wodny Warszawa, oraz Plaża Romantyczna</Trans>.</p>
        <p>3. <Trans>Większość rejsów odbywa się z Dworca Wodnego Warszawa</Trans>.</p>
        <p>4. <Trans>Harmonogram godzinowy i lokalizacje miejsc rozpoczęcia spływów znajduje się na stronie <Link to="/rejsy">wodnawarszawa.pl/rejsy</Link></Trans></p>
        <p>5. <Trans>Rejsy odbywają się od 1 maja 2022 do końca października 2022. W miesiącach maj - wrzesień rejsy odbywają się codziennie, w październiku - w weekendy</Trans>.</p>
        <h5 className="my-5 fw-bold">§3. <Trans>Rezerwacje</Trans></h5>
        <p>1. <Trans>Rezerwacje odbywają się tylko i wyłącznie na stronie <a href="/">wodnawarszawa.pl</a></Trans></p>
        <p>2. <Trans>Rezerwacji może dokonać osoba pełnoletnia dla maksymalnie 6 osób na jeden rejs. Za każdą osobę pobierana jest kaucja zwrotna w wysokości 15 zł</Trans>.</p>
        <p>3. <Trans>Dzieci mogą brać udział w rejsach wyłącznie pod opieką rodziców/opiekunów</Trans></p>
        <p>4. <Trans>Rejsy publikowane są z 2 tygodniowym wyprzedzeniem, z czego cykl rejsów na kolejny tydzień publikowany jest co tydzień w poniedziałki w godzinach wieczornych</Trans>.</p>
        <h5 className="my-5 fw-bold">§4. <Trans>Odwołanie, zmiany rezerwacji</Trans></h5>
        <p>1. <Trans>Odwołanie oraz zmiana rezerwacji odbywa się poprzez maila: <a href="mailto:kontakt@wodnawarszawa.pl">kontakt@wodnawarszawa.pl</a></Trans></p>
        <p>2. <Trans>Rejsy po stronie organizatora odwoływane są jedynie w przypadku warunkach, przy których żegluga mogłaby być niebezpieczna: wysoki stan wód, wiatr, alerty RCB</Trans>.</p>
        <p>3. <Trans>W przypadku odwołania rejsu w wyniki sił wyższych, ustalany jest z uczestnikami nowy termin rezerwacji, bądź zwracana kaucja</Trans>.</p>
        <p>4. <Trans><strong>Pogoda nie jest powodem do odwoływania rezerwacji</strong>. W deszczowe, zimne dni możliwa jest zmiana jednostki przez organizatora na taką, która posiada zadaszenie / ogrzewanie</Trans>.</p>
        <h5 className="my-5 fw-bold">§5. <Trans>Kaucja</Trans></h5>
        <p>1. <Trans>Przy dokonywaniu rezerwacji pobierana zostaje kaucja zwrotna w wysokości 15 zł / os. Kaucję należy wpłacić przy użyciu systemu rezerwacji <a target="_blank" href="https://tpay.com" >Tpay.com</a></Trans>.</p>
        <p>2. <Trans>Zwrot kaucji następuje automatycznie poprzez system rezerwacji dzień po rejsie. Transfer środków w zależności od banku może potrwać <strong>do 3 dni roboczych</strong>. W większości przypadków zwrot środków odbywa się natychmiastowo</Trans>.</p>
        <p>3. <Trans>Kaucja <strong>nie jest zwracana</strong>, jeśli uczestnik nie pojawił się na rejsie, bądź odwołał swoją rezerwację <strong>na mniej niż 24h</strong> przed planowaną rezerwacją</Trans>.</p>
        <h5 className="my-5 fw-bold">§6. <Trans>Pytania, uwagi</Trans></h5>
        <p>1. <Trans>Wszystkie pytania, uwagi prosimy kierować na adres mailowy: <a href="mailto:kontakt@wodnawarszawa.pl">kontakt@wodnawarszawa.pl</a></Trans></p>
      </PageSection>
    </Layout >
  )
}

export default TermsOfServicePageFreeTours

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["terms_free_tours", "navigation", "footbar"]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;